import React from "react"
import { Link } from "gatsby"

export default () => (
  <>
    <div className="wrapper mt-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center" style={{ fontSize: "5em" }}>
              404
            </h1>
            <h1 className="text-center">Página no encontrada</h1>
            <h1 className="text-center">Pagina non trovata</h1>
            <h1 className="text-center">Page non trouvée</h1>
            <h1 className="text-center">Página não encontrada</h1>
            <h1 className="text-center">Seite nicht gefunden</h1>
            <hr />
            <Link to="/">
              <h1 className="text-center" style={{ color: "green" }}>
                Startupedia
              </h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
)
